// html.legacyClass{
  .scroll-support {
    // background-color: #fff !important;
  }

  .upload-list {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 54px);

    &__content {
      width: 65%;
      margin: 3rem 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media(max-width: 1000px) {
        width: 85%;
      }

      @media(max-width: 768px) {
        width: 100%;
        padding: 15px;
        grid-template-columns: 1fr;
      }
    }
    
    &__item {
      height: 160px;
      padding: 15px 10px;
      list-style: none;
      border-bottom: 1px solid #b0a3bf;
      
      @media(max-width: 768px) {
        padding: 20px 0;
        border-top: 1px solid #b0a3bf;
        border-bottom: 0;
        grid-column: span 2;
      }

      &:first-child {
        height: 450px;
        padding: 0;
        grid-column: span 2;
        
        @media(max-width: 768px) {
          height: 350px;
          border-top: 0;
          grid-column: span 1;
        }

        .upload-list__item--link {
          flex-direction: column;
        }
        .upload-list__item--image {
          width: 100%;
          height: calc(100% - 95px);

          i {
            background-size: 20%;
          }
        }
        .upload-list__item--info {
          width: 100%;
          margin: 15px 0;

          p {
            max-height: 24px;
            -webkit-line-clamp: 1;
          }
        }
      }
      
      &:nth-child(even) {
        border-right: 1px solid #b0a3bf;
        
        @media(max-width: 768px) {
          border-right: 0;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      .title {
        margin-bottom: 0 !important;
      }

      .author {
        font-size: 12px !important;
        margin-bottom: 8px;
      }

      &--link {
        height: 100%;
        display: flex;
        align-items: center;
        color: #333;
        
        &:hover {
          color: #333;
        }
      }

      &--image {
        width: 35%;
        height: 100%;
        
        @media(max-width: 500px) {
          width: 40%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        i {
          width: 100%;
          height: 100%;
          display: block;
          background-size: 45%;
          background-position: center;
          background-repeat: no-repeat;

          &.generic {
            background-image: url(../images/icon-generic-type.png);
          }
          &.png {
            background-image: url(../images/icon-png.png);
          }
          &.pdf {
            background-image: url(../images/icon-pdf.png);
          }
          &.jpg {
            background-image: url(../images/icon-jpg.png);
          }
          &.jpeg {
            background-image: url(../images/icon-jpeg.png);
          }
          &.doc, &.docx {
            background-image: url(../images/icon-doc.png);
          }
          &.xls, &.xlsx {
            background-image: url(../images/icon-xls.png);
          }
          &.ppt, &.pptx {
            background-image: url(../images/icon-ppt.png);
          }
          &.mp4 {
            background-image: url(../images/icon-mp4.png);
          }
          &.mp3 {
            background-image: url(../images/icon-mp3.png);
          }
        }
      }

      &--info {
        width: 65%;
        margin: 0 10px;

        @media(max-width: 500px) {
          width: 60%;
        }

        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 48px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
// }