
html.legacyClass{
  iframe{
    border-width: 0;
  }

  .card {
    height: 350px;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
  }

  .card .front {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transition: .4s;
  }

  .title-front-card {
    color: #000;
    width: 100%;
    text-align: left;   
    padding: 0;
    transition: .3s;
    color: white;
    z-index: inherit;
    margin-left: 13px;
  }
  .percentage{
    position: absolute;
    margin: 10px;
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    top: 90%;
    color: white;
    font-size: 11px;
  }


  .title-front-card {
    font-family: Arial;
    font-size: 20px;
  }
  .title-span{
    text-align: left !important;
    padding-left: 4%;
  }
  .subtitle-span{
    text-align: left !important;
    padding-left: 4%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 4%;
  }

  .card-progress{
    border: 2px red solid;
    background-color: red;
    z-index: 100;
    opacity: 1;
  }
  .column{
    flex-direction: column-reverse;
    display: flex;
  }

  .card-margin{
    margin:1;
    margin-top:-1
  }

  .rating{
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
    margin-left: 4%;
    z-index: 0;
    color: white;
  }
  .div-progress{
    display:flex;
    background-color:black;
    margin-left:5%;
    width:30%;
    align-self: left;
    z-index: 0;
  }
  .div-card-hibridy{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1
  }
  .div-card-hibridy > img{
    width: 40%;
  }
  @media only screen and (max-width: 768px) {
    .title-front-card {
      font-size: 14px;
      
    }
    .title-span{
      text-align: center !important;
    }
    .subtitle-span{
      text-align: center !important;
      padding-left: 0;
    }
    .rating{
      font-size: 15px;
      display: flex;
      justify-content: center;
      filter:none;
      z-index: 0;
      margin-left: 0;
    }
    .cardGridContainer {
      padding: 15px 30px 30px 30px;
      overflow: scroll;
      width: 100%;
      height: 100%;
      min-height: calc(100vh - 54px - 000px);
      margin-bottom: 000px;
    }
    .cardGridContainer .cardGrid{
      justify-content: flex-end;
      position:'unset';
      height: 300;
      border: 3px solid gray;
      border-radius: 0;

    }
    .card {
      height: 230px;
      max-width: 100%;
      margin: 0 auto;
    }
    .div-progress{
    
      width:40%;
      align-self: center;
    }
  }


  @media only screen and (min-width: 769px) {
    .cardGridContainer {
      padding: 50px 110px 100px 110px;
      width: 100%;
      height: 100%;
      min-height: calc(100vh - 54px - 000px);
    }
    .card-margin{
      margin:0 !important;
      margin-top:-1 !important;
    }
    .cardGridContainer .cardGrid{
      justify-content: flex-end;
      position:'unset';
      height: 300;
      border: 2px solid gray;
      border-radius: 0;
    }
  }

  .back span {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }



  .front:hover> .rating {
    filter: none;

  }
  .front:hover   {
    filter: brightness(60%);
    -webkit-filter: brightness(60%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .alignCards {
    margin: 0px 0px 30px 0px;
  }
}