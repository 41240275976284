@import '../constant.scss';

// flickity

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  &.is-draggable {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    .flickity-viewport {
      cursor: move;
      cursor: -webkit-grab;
      cursor: grab;
  
      &.is-pointer-down {
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }
    }
  }
  
  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;

    .flickity-slider {
      position: absolute;
      width: 100%;
      height: 100%;

      @include controlViewCard;

    }
  }

  /* ---- flickity-button ---- */

  .flickity-button {
    position: absolute;
    background: transparent;
    border: none;
    color: var(--stand-02-100-color);
    width: 30px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;

    @media (max-width: $sm) {
      display: none;
      width: 16px;
      height: 100%;
    }

    &:hover {
      background: transparent;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &:active {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }

    &-icon {
      fill: var(--stand-02-100-color);
      position: relative;
      width: 30px;
      z-index: 1;
      top: -40px;
  
      @media (max-width: $sm) {
        width: 16px;
        top: -10px;
      }
    }

    &.previous {
      left: -22px;
  
      @media (max-width: $sm) {
        left: -16px;
      }
  
      &::before {
        position: absolute;
        content: "";
        width: 45px;
        height: 100%;
        top: 0;
        left: 20px;
        z-index: 0;
        background: linear-gradient(90deg, var(--stand-01-100-color) 0%, var(--stand-01-0-color) 100%);
        pointer-events: none;
  
        @media (max-width: $sm) {
          display: none;
        }
      }
    }
  
    &.next {
      right: -22px;
  
      @media (max-width: $sm) {
        right: -16px;
      }
  
      &::before {
        position: absolute;
        content: "";
        width: 45px;
        height: 100%;
        top: 0;
        right: 20px;
        background: linear-gradient(270deg, var(--stand-01-100-color) 0%, var(--stand-01-0-color) 100%);
        z-index: 0;
        pointer-events: none;
  
        @media (max-width: $sm) {
          display: none;
        }
      }
    }
  }

  /* ---- page dots ---- */
  .flickity-page-dots {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    margin-top: 15px;
    list-style: none;
    text-align: center;
    line-height: 1;

    display: none;
    @media (max-width: $sm) {
      display: block;
      position: relative;
    }

    .dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 8px;
      background: var(--stand-02-100-color);
      border-radius: 50%;
      opacity: 0.25;
      cursor: pointer;

      @media (max-width: $md) {
        width: 5px;
        height: 5px;
        margin: 0 4px;
      }

      &.is-selected {
        opacity: 1;
      }
    }
  }
}

