$sm: 640px;
$md: 768px;
$lg: 1024px;
$mv: 1600px;

$widthScroll: 10px;
$widthView: 93%;
$widthViewMobile: 90%;
$heightHeader: 115px; //90px
$marginFirstBlock: 80px;
$heightFooter: 90px;
$mtFooter: 32px;

$heightHeaderEK: 100px;
$heightFooterEK: 74px;

$blockMarginS: 25px;
$blockMarginM: 50px;
$blockMarginL: 100px;

$spacing-01: 4px;
$spacing-02: 8px;
$spacing-03: 16px;
$spacing-04: 24px;
$spacing-05: 40px;
$spacing-06: 64px;
$spacing-07: 104px;

$heightHeaderContentRef: calc($heightHeader + $blockMarginS);
$contentMinHeight: calc(100vh - $heightHeaderContentRef - $mtFooter);

$font-family-01: "SF Pro Text", "SF Pro Display", sans-serif;
$font-family-02: "SF Pro Display", "SF Pro Text", sans-serif;

$font-size-small: 12px;
$font-size-small-02: 14px;
$font-size-paragraph: 16px;
$font-size-h1: 28px;
$font-size-h2: 24px;
$font-size-h3: 21px;
$font-size-h4: 18px;

$font-line-height-small: 12px;
$font-line-height-paragraph: 20px;
$font-line-height-h1: 34px;
$font-line-height-h2: 29px;
$font-line-height-h3: 25px;
$font-line-height-h4: 21px;
  
$border-radius-01: 4px;
$border-radius-02: 9px;

@mixin aspectRatio {
  &[data-aspectratio="square"] {
    aspect-ratio: 1;
  }
  &[data-aspectratio="portrait"] {
    aspect-ratio: 3/4;
  }

  &[data-aspectratio="landscape-xsmall"] {
    aspect-ratio: 4/3;
  }

  &[data-aspectratio="landscape-small"] {
    aspect-ratio: 96/25;
  }

  &[data-aspectratio="landscape-middle"] {
    aspect-ratio: 64/23;
  }

  &[data-aspectratio="landscape-large"] {
    aspect-ratio: 32/15;
  }
}


@mixin scrollCustom {
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #3c4043;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    cursor: pointer;

    &:hover {
      cursor: move;
      background-color: var(--primary-color);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}

@mixin controlViewCard {
  .pctCardAvatar,
  .pctCardStandard,
  .pctCardNavigation {
    @media (max-width: $lg) {
      width: 33.3% !important;
    }

    @media (max-width: $md) {
      width: 50% !important;
    }

    @media (max-width: $sm) {
      width: 100% !important;
      margin-right: 16px;
    }

    &.pctCardAvatar {
      @media (max-width: $sm) {
        width: 50% !important;
        margin-right: 0;
      }
    }
  }

  .containerBtn {
    position: relative;
    width: 100%;
    padding: 4px;
    // height: inherit;

    @media (max-width:$lg) {
      width: 33.3% !important;
    }

    @media (max-width:$md) {
      width: 50% !important;
    }

    @media (max-width: $sm) {
      width: 50% !important;
      padding: 4px;
    }

    button {
      min-height: 52px;
    }
  }
}

@mixin syllabusCustom {
  p,
    div,
    span,
    li {
      position: relative;
      color: var(--dark-color);
    }
  
    ul {
      padding: 10px 0px 10px 34px;
      list-style-type: disc;
  
      // li {
      //   list-style: disc;
      // }
    }
  
    ol {
      padding: 10px 0px 10px 34px;
      list-style-type: decimal;
      // list-style: decimal;
  
      // li {
      //   list-style-type: decimal;
      //   list-style: decimal;
      // }
    }
  
    em {
      font-style: italic;
    }
  
    a {
      text-decoration: underline;
      color: #1890ff;
  
      &:hover {
        text-decoration: underline !important;
        color: #1890ff;
      }
    }

  @include scrollCustom; // incluido do constant.scss
}