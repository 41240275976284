.pctPaginate {

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    ul {
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        list-style-type: none;
        max-width: 500px;
        gap: 10px;

        @media (max-width: $sm) {
            width: 100%;
        }

        li {

            a {

                padding: 3px 6px;
                border: var(--stand-02-100-color) 1px solid;
                cursor: pointer;
                background-color: var(--stand-02-10-color);
                color: var(--stand-02-100-color);

                @media (max-width: $sm) {
                    padding: 2px 2px;
                }

            }

            &.previous,
            &.next,
            &.break {
                a {
                    border-color: transparent !important;
                    background-color: transparent !important;
                    color: var(--stand-02-100-color) !important;
                }

                @media (max-width: $sm) {
                    font-size: 13px;
                }

            }

            &.selected,
            &:hover {
                a {
                    background-color: var(--primary-color);
                    border-color: transparent;
                    color: var(--dark-color);
                    min-width: 32px;
                }
            }

            &.disabled,
            &.disable {
                color: var(--stand-02-100-color);
                opacity: 0.3;

                a {
                    cursor: default;
                }
            }
        }
    }
}