// html.legacyClass{
  .multiple-format-list {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 54px);

    &-error {
      margin-top: 280px;
      text-align: center;
    }

    &__content {
      width: 65%;
      margin: 1rem 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: 1000px) {
        width: 85%;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
        grid-template-columns: 1fr;
      }
    }

    &__item {
      height: 160px;
      padding: 15px 10px;
      list-style: none;
      border-bottom: 1px solid #b0a3bf;

      @media (max-width: 768px) {
        padding: 20px 0;
        border-top: 1px solid #b0a3bf;
        border-bottom: 0;
      }

      &:first-child {
        height: 450px;
        padding: 0;
        grid-column: span 2;
        padding-bottom: 10px;

        @media (max-width: 768px) {
          height: 350px;
          border-top: 0;
          grid-column: span 1;
          margin-bottom: 10px;
        }

        .multiple-format-list__item--link {
          flex-direction: column;
        }
        .multiple-format-list__item--image {
          width: 100%;
          height: calc(100% - 120px);
        }
        .multiple-format-list__item--info {
          width: 100%;
          margin: 15px 0;

          p {
            max-height: 24px;
            -webkit-line-clamp: 1;
          }
        }
      }

      &:nth-child(even) {
        border-right: 1px solid #b0a3bf;

        @media (max-width: 768px) {
          border-right: 0;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      .title {
        text-align: start;
        margin-bottom: 10px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 18px;
        color: black;
        font-weight: 500;
      }

      .author {
        font-size: 14px !important;
        margin-bottom: 8px;
      }

      .type-icon {
        .icon {
          font-size: 50px;
          opacity: 0.5;
          color: "";
        }
      }

      &--link {
        height: 100%;
        display: flex;
        align-items: center;
        color: #333;

        &:hover {
          color: #333;
        }
      }

      &--image {
        width: 35%;
        height: 100%;

        @media (max-width: 500px) {
          min-width: 35%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &--info {
        width: 65%;
        margin: 0 10px;

        @media (max-width: 500px) {
          width: 60%;
        }

        p {
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 48px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
// }
