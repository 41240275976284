.containerMandatoryLock{
  position: absolute;
  width: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 100%;
  top: 6px;
  right: 6px;

  @media (max-width: $sm){
    top: 4px;
  }

  .pctMandatory{
    font-size: 14px;
    top: -2px;

    @media (max-width: $sm){
      top: -3px;
      font-size: 12px;
      position: relative;
    }
  }

  &.containerMandatoryLock2{
    top: 0;
    width: 20px;
  }
}

.pctCard{
  .dis{
    position: relative;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    gap: 16px;
    width: 100%;
    text-align: right;
    line-height: 15px;

    .dis2{
      // margin-top: -10px;
      z-index: 4;
      justify-content:flex-end;
      width: 100%;
      color: var(--suport-success-color);
      font-size: 12px;
      // margin-right: 6px;
    }

    .dis3{
      // margin-top: -10px;
      z-index: 4;
      justify-content:flex-end;
      width: 100%;
      color: var(--primary-color);
      font-size: 12px;
    }

    .dis4{
      // margin-top: -10px;
      z-index: 4;
      justify-content:flex-end;
      width: 100%;
      color: var(--suport-expired-color);
      font-size: 12px;

      svg{
        position: relative;
        top: 1px;
        margin-right: 2px;
      }
    }

    // .dis5{
    //   z-index: 9;
    //   justify-content:flex-end;
    //   width: 100%;
    //   color: var(--suport-failed-color);
    //   font-size: 12px;

    //   svg{
    //     position: relative;
    //     top: 1px;
    //     margin-right: 2px;
    //   }
    // }

    
    .disStatus {
      position: relative;
      z-index: 9;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      width: 100%;
      font-size: 12px;

      svg {
        position: relative;
        font-size: 13px;
      }

      .infoGrade {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        color: var(--primary-color);
      }

      .tooltipIt {
        cursor: pointer;
        position: static;

        .tooltipItInfo {
          position: absolute;
          padding: 4px;
          width: 100%;
          border-radius: 5px;
          background: var(--dark-color);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border: 1px solid transparent;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: -0.01em;
          line-height: 18px;
          text-align: left;
          color: var(--light-color);
          top: -70px;
          left: 0;
          display: none;
        }
        
               
        &:focus,
        &:active, 
        &:hover {
        
          .tooltipItInfo {
            display: block;
          }
      
        }

        svg{
          font-size: 20px;
          color: var(--primary-color);
        }
      }

      .disRef{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        color: var(--suport-success-color);
      }


      &.disFailed{
          .disRef {
            color: var(--suport-failed-color);
            gap: 5px;

            svg {
              position: relative;
              font-size: 12px;
            }
          }
      }
      
    }
  }
}