// html.legacyClass{
  .ChatRoom {
    text-align: center;
    width: 100%;
    position: relative;
  }
  
  .ChatRoom-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .ChatRoom-logo {
      animation: ChatRoom-logo-spin infinite 20s linear;
    }
  }
  
  .ChatRoom-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    flex: 1;
  }

  .chat-room-container  {
    background-color: rgb(240, 237, 240);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 2px 4px #a7a7a7;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .chat-room-msg{
    background-color: #861414;
    flex: 1 1;
    padding-top: 15px;
    height: calc(100vh - 54px);
    overflow-y: clip;
  }

  .chat-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: gray;
    font-size: 16px;
  }

  .chat-list-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #933939;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    transition: left 0.3s ease;
  }

  .chat-list-content{
    padding: 15px;
    margin: 15px;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(255,255,255,0.4);
    overflow: auto;
  }

  @keyframes fadeInUserList {
    0% {opacity:0; margin-top: 20px}
    100% {opacity:1; margin-top: 10px}
    }

  .chat-list-user{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 18px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    margin-top: 10px;
    animation: fadeInUserList 0.6s ease;
  }

  .chat-list-header{
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 24px;
    color: #adadad;
    font-weight: 100;
  }

  .chat-list-close{
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 20px 0 0;
    z-index: 999;
  }

  .chat-close {
    position: absolute;
    left: 10px;
    top: 60px;
    padding: 13px 0 0 20px;
    z-index: 999;
  }

  .chat-close:hover {
    cursor: pointer;
  }

  .chat-list{
    position: absolute;
    right: 0;
    top: 0;
    padding: 13px 20px 0 0;
    z-index: 999;
  }
  
  .ChatRoom-link {
    color: #61dafb;
  }

  .chat-send-logo {
    position: absolute;
    right: 16px;
    height: 35px;
    bottom: 23px;
    z-index: 10;
    cursor: pointer;
  }

  li {
    color: white;
  }
  
  @keyframes ChatRoom-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .chatbox-container { 
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: column;
    border-radius: 5px;
    padding: 12px 15px 30px 15px !important;
    background-color: #620606;
    overflow-y: scroll;
  }
  .chatHeader {
    padding-top: 13px;
    width: 100%;
    height: 60px;
  }
  .info {
    color: #888;
    font-size: 16px;
  }
  hr {
    height: 1px;
    margin: 0;
    border: none;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .bottomBar {
    align-self: flex-end;
    z-index: 2;
    display: flex;
    flex-flow: row;
    width: 100%;
    padding: 5px 0px 5px 15px;
    position: relative;
  }
  .title {
    text-align: center;
    font-size: 24px;
    color: #adadad;
    font-weight: 100;
  }

  .chat-list-item{
    background-color: rgba(255,255,255,0.25);
    padding: 10px;
    margin: 0px 10px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  .chatBox {
    overflow-x: hidden;
    height: 200px;
    font-size: 20px;
    padding: 10px;
    overflow-y: scroll;
    background-color: white;
    flex: 1;
    width: 100%;
    border-radius: 5px;
  }

  .chatBox:after {
    content: "";
    height: 15px;
    display: block;
}

  .chatInput {
    width: calc(100% - 70px);
    background-color: #933939;
    margin: 10px 0;
    font-size: 18px;
    color: white;
    border: 1px solid #333;
    font-weight: 100;
    padding: 10px;
    border-radius: 5px;
  }

  .chat {
    display: flex;
    margin-top: 20px;
    word-wrap: break-word;
    flex-direction: column;
    align-items: flex-start;
    background-color: lightgray;
    padding: 5px 10px;
    border-radius: 5px;
    word-break: break-word;
    max-width: 100%;
    position: relative;
  }

  .chat-logged {
    display: flex;
    margin: 15px 0 15px;
    word-wrap: break-word;
    justify-content: flex-start;
    width: 100%;
  }
  
  @keyframes fadeInMenu {
    0% {opacity:0; margin-right: 10px}
    100% {opacity:1; margin-right: 0px}
    }

  @keyframes fadeInMenuOther {
    0% {opacity:0; margin-left: -10px}
    100% {opacity:1; margin-left: 0px}
    }

  .chat-container-mine{
    display: flex;
    justify-content: flex-end;
    animation: fadeInMenu ease 0.3s;
  }  
  
  .chat-container-other{
    display: flex;
    justify-content: flex-start;
    animation: fadeInMenuOther ease 0.3s;
  }

  .chat-mine{
    display: flex;
    margin-top: 5px;
    word-wrap: break-word;
    background-color: #861414;
    border-radius: 5px;
    padding: 5px 10px;
    word-break: break-word;
    max-width: 100%;
    position: relative;
  }
  
  .chat-mine-arrow:after, .chat-mine-arrow:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .chat-mine-arrow:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #861414;
    border-width: 7px;
    margin-top: -7px;
  }

  .chat-arrow:after, .chat-arrow:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .chat-arrow:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: lightgray;
    border-width: 7px;
    margin-top: -7px;
  }

  .chat > .msg {
    color: black;
  }

  .msg {
    color: white;
    text-align: left;
  }
  .chat :first{
    margin-top: -30px;
  }
  .name {
    margin-right: 5px;
    font-weight: bold;
  }
// }