// html.legacyClass{
    .body {
        background-color: white;
        height: 100%;
    }

    .search {
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin-top: 20px; */
        padding: 20px;
    }

    .searchInput {
        border-radius: 20px;
        width: 230px;
        height: 35px;
        border: 10px;
        background-color: #ededed;
        outline: none;
        padding: 0px 15px;
    }

    .listar-perfis-item {
        cursor: pointer;
    }

    .listar-perfis-item:hover {
        background-color: rgba(0,0,0,0.1);
    }

    #button {
        border-radius: 20px;
        border: 10px;
        width: 100px;
        height: 50px;
        margin: 20px;
    }
    .div-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
// }