// html.legacyClass{
  .mapa-container {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 54px - 5rem);
    margin-bottom: 5rem;
    padding: 2px;
    background-color: white;
    box-sizing: border-box;
  }

  .mapa-item:hover {
    color: white;
    text-decoration: none;
    opacity: 0.8;
    transition: 0.3s;
  }

  .mapa-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    flex: 1;
    min-width: 33%;

    &:nth-child(1) {
      min-width: 100%;
    }

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }

  .banner-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 216px;
  }

  .banner-tile {
    width: 100%;
    position: relative;
    height: 100%;
    display: block;
    width: 50%;
    flex-grow: 1;
    min-height: 220px;
    box-sizing: border-box;
    // padding: 2px;
    justify-content: space-between;
    &-image {
      height: 100%;
    }
    @media (max-width: 768px) {
      &-image {
        min-width: 100%;
      }
    }
  }

  .banner-tile-text {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    bottom: 42%;
    width: 100%;
  }

  .banner-tile-text div {
    text-align: center !important;
  }
// }
