html.legacyClass{
  .card-deck {
      margin: 0 -15px;
      justify-content: space-between;
  }

  .card-deck .card {
      height: auto;
      border: none;
  
  }

  .card-img-top {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
  }

  .cardImage {
    width: 80%;
    height: 200px;
    margin: 0 auto;
  }

  .cardPlayerContainer {
      padding: 50px 0px;
      padding-right: 5%;
      padding-left: 5%;
      min-height: calc(100vh - 54px - 000px);
      overflow-x: hidden;
  }

  .card-title {
      font-size: 20px;
      line-height: 24px;
      white-space: break-spaces;
      margin-bottom: 0.25rem !important;
  }

  .card-author {
      font-size: 16px;
      white-space: break-spaces;
      margin-bottom: 0.75rem;
  }

  .card-body {
      text-align: left;
      padding: 20px 0 0 !important;
      white-space: nowrap;
      width: 30rem;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .circlePlay {
      background-color: rgba(255, 255, 255, 0.3);
      padding: 10px;
  }

  .body-range{
    padding-bottom: 25px;
  }
  .rhap_download-progress{
    background: transparent !important;
  }

  .colPlayer {
    padding-top: 40px;
    padding-bottom: 20px ;
  }

  .rowPlayer > .colPlayer {
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
  }
  .rowPlayer > .colPlayer:nth-child(3n){
    border-right: none;  
  }
  .rowPlayer > .colPlayer:last-child {
    border-bottom: none;  
  } 

  @media (min-width: 768px) {
    .card-deck .card {
        margin-right: 5px !important;
        margin-left: 5px !important;
        padding-left: 40px;
        padding-right: 40px;
    }
  }

  .statusPlay {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
    
  @media (max-width: 767px) {
    .cardImage > .card-img-top {
      max-height: 110px;
    }
    .rowPlayer > .colPlayer {
      border: 0;
      border-bottom: 1px solid #333;
    }
    .statusPlay {
      justify-content: flex-start;
    }
    .card-deck .card {
        padding: 12px;
    }
    .cardResponsive {
        display: flex !important;
        flex-direction: row;
    }
    .cardImage {
        max-width: 35%;
        width: 35%;
        height: auto;
        padding: 10px;
        min-height: 120px;
        display: flex;
    }
    .colPlayer {
        padding: 0px !important;
        border-right: none;
    }
    .card-deck .card {
        margin-bottom: 0px !important;
        max-width: 100%;
    }
    .cardPlayerContainer {
        background-color: #491b5c;
        padding-top: 0px !important;
        padding-bottom: 0px;
    }
    .card-title {
      text-align: initial;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
    .card-author {
      font-size: 14px;
      text-align: initial;
      margin-bottom: 0.25rem;
    }
  }

  @media(min-width:700px){
      .colPlayer {
          border: none;
      }

      .card-body {
          white-space: break-spaces;
          width: auto;
      }
  }

  .activate-account-body {
    padding: 15px !important;
    background-color: white;
    cursor: auto;
  }


  .rhap_time{
      color: #000 !important;
      position: absolute;
      top: 65px;
  }

  .rhap_current-time {
    left: 30px;
  }

  .rhap_total-time {
    right: 30px;
  }
    
  .rhap_progress-indicator{
      background: #000 !important;
      width: 22px !important;
      height: 22px !important;
      top: -8px !important;
      margin-left: 0px;
      border-radius: 50%;
  }

  .rhap_additional-controls{
      display: none !important;
  }
  .rhap_volume-container{
      display: none !important;
  }
  
  .rhap_controls-section{
      display: none !important;
  }

  .rhap_progress-container{
      margin: 20px;
      outline: none;
  }
  .rhap_container {
      outline: none;
  }
}
