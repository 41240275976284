*{
  box-sizing: border-box;
}

html.legacyClass{
  @import "./legacy/bootstrap/scss/bootstrap.scss";
  @import "./legacy/index.scss";
}

@import "./peoplecraft/fonts/sfpro/stylesheet.css",
        "./peoplecraft/fonts/font-awesome/css/font-awesome.min.css",
        './peoplecraft/variables.scss',
        './peoplecraft/constant.scss',
        './peoplecraft/base//paginate.scss',
        './peoplecraft/base/scrollBarCustom.scss';

html.peoplecraftClass{
  @import './peoplecraft/base/reset.scss',
          './peoplecraft/base/structure.scss',
          './peoplecraft/base/general.scss',
          './peoplecraft/base/flickity.scss';
}
