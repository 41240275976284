@import "../../../themes/peoplecraft/constant.scss";


.pctAdminUpgrade {

    position: relative;
    width: 1100px;
    
    @media (max-width: $lg) {
       width: 100%;
    }

    justify-content: flex-start;

    &.upgrade {
        padding: 20px;
    }

    
    &.feedback {
        .title {
            max-width: 600px;
            margin: 0 auto;
            padding: 40px;

            h3 {
                margin-bottom: 20px;
            }
        }
    }
    
    .buttonsWrapper {
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.1);
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-top: 20px;

        button {
            // background-color: var(--primary-color);
            border-radius: 4px;
        }
    }
}