// html.legacyClass{
    .card-points{
        margin-top: 10px;
        width: 50%;
    }
    .profile-card-points-header {
        display: flex;
        justify-content: space-between;
        margin-left: 22px;
        align-items: flex-end !important;
    }
    .section{
        display: flex;
        flex-wrap: wrap;
        padding-left: 2%;
        padding-right: 2%;
        align-items: center;
    }
    .point-section {
        display: flex;
        flex-wrap: wrap;
        padding-left: 2%;
        padding-right: 2%;
    }
    .column{
        flex-direction: column !important;
        margin-top: 10%;
        margin-bottom: 0;
    }
    @media(max-width: 768px){
        .column{        
            margin-top: 4%;
        }
        .card-points{
            margin-top: 10px;
            width: 100%;
        }
        .second-section{
            margin-top: 4px;
        }
    }
// }