@import "../../../themes/peoplecraft/constant.scss";

.ModalUpgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  //quando existir um load antes da chamada da modal
  z-index: 9999999999991;
  background-color: transparent;
  background-image: linear-gradient(
    90deg,
    var(--stand-01-90-color) 100%,
    var(--stand-01-0-color) 56%,
    var(--stand-01-10-color) 95%,
    var(--stand-01-10-color) 100%
  );
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;

  @media (max-width: $md) {
    padding: 0px;
  }
  @media (max-width: $sm) {
    // height: auto;
    &.modal-client-upgrade {
      height: 100vh;
    }
  }

  &.modal-feedback {
    > div {
      width: 80% !important;
      justify-content: start !important;
      padding: $spacing-05 0 0;
    }
    @media (max-width: $sm) {
      padding: 0;
      height: 100%;
      > div {
        height: 100% !important ;
        width: 100% !important;
      }
    }
  }

  .modal-body-pct {
    position: relative;
    background-color: var(--dark-color);
    box-shadow: 0px 0px 1px 0px rgb(222 222 222);
    text-align: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    // max-width: 1100px;
    padding: 0px;
    max-height: 95vh;
    overflow: hidden auto;
    margin: 0 10px;
  
    @media (max-width: $lg) {
      min-width: auto;
      top: 0;
      justify-content: start;
      // height: 100vh;
      overflow: hidden auto;
      padding-top: 20px;
    }
    
    .closeBtn {
      position: absolute;
      font-size: 30px;
      top: 10px;
      right: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      color: white;
      z-index: 10;
    }
  }
}
