html,
body {
  overflow: auto!important;
  padding: 0;
  margin: 0!important;
  font-family: $font-family-01;
  font-size: $font-size-paragraph;
  line-height: $font-line-height-paragraph;
  background-color: var(--stand-01-color);
  color: var(--stand-02-100-color);
  // scroll-behavior: smooth;

  &.bodyOverflowHidden {
    overflow: hidden !important;
  }
}


.App{
  position: relative;
  width: 100%;
  min-height: 100vh; 

  .pctPage {
    height: auto !important;
    width: 100%; 
    position: relative;
    min-height: calc(100vh - ( $heightFooter + $mtFooter ) );// height(64) +marg-top(32) do footer


    iframe{
      z-index: 1!important;
    }

    .pctMasterBannerList {
      .pctMasterBanner {
        margin-bottom: 50px;
      }
    }

    // Controle de Margem do primeiro elemento das páginas com blocos 
    &.pctPageBlock{
      & > :first-child {
        padding-top: $heightHeader;
        &.pctMasterBanner, 
        &.pctMasterBannerList{
          padding-top: 0;
        }

        &.pctListContainer {
          & > :first-child {
            &.sectionList{
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}


.sectionList{
  position: relative;
  width: $widthView;
  margin: 0 auto;
  // padding: 30px 10px;
  // padding: 30px 0;

  @media (max-width: $sm){
    width: $widthViewMobile;
  }
}

.section_mb{
  &.section_mb_none{
    padding-bottom: 0;
  }
  &.section_mb_s{
    padding-bottom: $blockMarginS;
  }
  &.section_mb_m{
    padding-bottom: $blockMarginM;
  }
  &.section_mb_l{
    padding-bottom: $blockMarginL;
  }
}
.section_mt{
  &.section_mt_none{
    padding-top: 0;
  }
  &.section_mt_s{
    padding-top: 25px;
  }
  &.section_mt_m{
    padding-top: 50px;
  }
  &.section_mt_l{
    padding-top: 100px;
  }
}