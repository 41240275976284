
.dark-mode{
  *::-webkit-scrollbar {
    width: $widthScroll;
  }
  
  /* Track */
  *::-webkit-scrollbar-track {
    // background-color: var(--secondary-color);
    background-color: #3c4043;
  }
  
  /* Handle */
  *::-webkit-scrollbar-thumb {
    // background-color: var(--black-color);
    // border: 2px solid var(--stand-01-100-color);
    background-color: #1b1b1b;
    border: 1px solid #282929;
    cursor: pointer;
    &:hover {
      cursor: move;
      // background-color: var(--black-color);
      background-color: #282929;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}