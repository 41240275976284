// html.legacyClass{
  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 2px;
  }

  .ytplaylist-row {
    padding: 0;
  }

  .item {
    min-width: calc(33.33% - 4px);
    flex: 1;
    margin: 2px;
  }

  .cardline {
    overflow: hidden;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cardline:hover {
    -webkit-filter: brightness(60%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .title-front-card {
    font-family: Arial;
    text-align: right;
  }

  .cardline .title-front-card {
    background: rgba(255, 255, 255, 0);
    position: absolute;
    bottom: 0;
    color: #000;
    left: 0;
    right: 0px;
    padding: 10px;
    transition: .3s;
    font-size: 26px;
    color: white;
    width: 100%;
  }

  .video-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
    padding: 2rem 8% 3rem;
  }

  @media only screen and (max-width: 900px) {
    .video-list {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;
      padding: 0;
      margin: 0 -15px;
    }
  }

  @media only screen and (max-width: 768px) {
    .title-front-card {
      text-align: right;
    }
    .cardline .title-front-card {
      font-size: 16px;
    }
    .row .cardline {
      height: 184px;
    }
  }

  @media only screen and (min-width: 769px) {
    .cardline .title-front-card {
      width: 100%;
    }
    .row .cardline {
      height: 45vh;
      min-height: 275px;
    }
  }

  .cards-container{
    min-height: calc(100vh - 54px - 000px);
  }
// }