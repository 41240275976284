@mixin defineVars($id, $value: null ) {

  @if not $value {
    $value: $id
  }

  --#{$id}-color: rgba(var(--#{$value}), 1);
  --#{$id}-100-color: rgba(var(--#{$value}), 1);
  --#{$id}-90-color: rgba(var(--#{$value}), 0.9);
  --#{$id}-80-color: rgba(var(--#{$value}), 0.8);
  --#{$id}-60-color: rgba(var(--#{$value}), 0.6);
  --#{$id}-70-color: rgba(var(--#{$value}), 0.7);
  --#{$id}-50-color: rgba(var(--#{$value}), 0.5);
  --#{$id}-40-color: rgba(var(--#{$value}), 0.4);
  --#{$id}-20-color: rgba(var(--#{$value}), 0.2);
  --#{$id}-10-color: rgba(var(--#{$value}), 0.1);
  --#{$id}-05-color: rgba(var(--#{$value}), 0.05);
  --#{$id}-0-color: rgba(var(--#{$value}), 0);
}

:root {

  --sm: 640;
  --md: 768;
  --lg: 1024;
  --mv: 1600;

  --primary: 134, 208, 211; // color: #86d0d3
  @include defineVars('primary');
 
  --secondary: 254, 153, 32; // color:#fe9920
  @include defineVars('secondary');

  --tertiary: 238, 238, 238; // color:
  @include defineVars('tertiary');

  --quaternary: 39, 35, 83; // color:#272353
  @include defineVars('quaternary');

  --suport-success: 53, 179, 82; // color: #35b352
  @include defineVars('suport-success');

  --suport-dark-success: 49,96,59; // color: #31603B
  @include defineVars('suport-success-dark');
  
  --suport-failed: 197, 68, 68; // color: #c54444
  @include defineVars('suport-failed');

  --suport-dark-failed: 155,56, 56; // color: #9B3838 
  @include defineVars('suport-failed-dark');
  
  --suport-expired: 204, 91, 8; // colr:#cc5b08;
  @include defineVars('suport-expired');

  --suport-04: 159, 159, 162; // color:#9f9fa2
  @include defineVars('suport-04');

  --black: 51, 51, 51; // color:#333333
  @include defineVars('black');
  
  --dark: 54, 54, 54; // color:#0e1224
  @include defineVars('dark');

  --deep: 33, 31, 38; // color:#211F26
  @include defineVars('deep');

  --light: 255, 255, 255; // colr:#fff
  @include defineVars('light');

  @include defineVars('stand-01', 'dark');
  @include defineVars('stand-02', 'tertiary');
}


.light-mode{

  @include defineVars('stand-01', 'light');
  @include defineVars('stand-02', 'dark');

  .light-not{
    @include defineVars('stand-01', 'light');
    @include defineVars('stand-02', 'dark');
  }

}

