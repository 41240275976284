// html.legacyClass{
  .menu-row-container {
    min-height: calc(100vh - 54px);
    margin-bottom: 5rem;
    padding: 1px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    height: 100%;
  }

  .menu-row-item {
    width: 33.33%;
    flex-grow: 1;
    min-height: 250px;
    box-sizing: border-box;
    padding: 3px;
    justify-content: space-between;

    &:nth-child(1) {
      min-width: 100%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .menu-row-image {
    position: relative;
    height: 100%;
    background-position: center !important;
    background-color: rgba(0,0,0,0.1);
  }

  .menu-row-item:hover {
    color: white;
    text-decoration: none;
    opacity: 0.8;
    transition: 0.3s;
  }
// }