ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
main,
button,
section,
article {
  margin: 0;
  padding: 0;
}

button{
  background: transparent;
  border: none;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


