// html.legacyClass{
    .bodyDescription{
        display: flex;
        justify-content: center;
        padding-top: 20px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .bodyTitle{
        line-height: 24px;
    }
    .bodyAuthor{
        line-height: 24px;
        padding-top: 4px;
    }


    @media screen and (min-width: 800px) {
        .bodyDescription{
            padding-top: 0px;
        }
    }
// }