@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Regular.eot');
    src: url('SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Regular.woff2') format('woff2'),
        url('SFProDisplay-Regular.woff') format('woff'),
        url('SFProDisplay-Regular.ttf') format('truetype'),
        url('SFProDisplay-Regular.svg#SFProDisplay-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-RegularItalic.eot');
    src: url('SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('SFProDisplay-RegularItalic.woff') format('woff'),
        url('SFProDisplay-RegularItalic.ttf') format('truetype'),
        url('SFProDisplay-RegularItalic.svg#SFProDisplay-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-Bold.eot');
    src: url('SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-Bold.woff2') format('woff2'),
        url('SFProDisplay-Bold.woff') format('woff'),
        url('SFProDisplay-Bold.ttf') format('truetype'),
        url('SFProDisplay-Bold.svg#SFProDisplay-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('SFProDisplay-BoldItalic.eot');
    src: url('SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SFProDisplay-BoldItalic.woff2') format('woff2'),
        url('SFProDisplay-BoldItalic.woff') format('woff'),
        url('SFProDisplay-BoldItalic.ttf') format('truetype'),
        url('SFProDisplay-BoldItalic.svg#SFProDisplay-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'SF Pro Text';
    src: url('SFProText-Regular.eot');
    src: url('SFProText-Regular.eot?#iefix') format('embedded-opentype'),
        url('SFProText-Regular.woff2') format('woff2'),
        url('SFProText-Regular.woff') format('woff'),
        url('SFProText-Regular.ttf') format('truetype'),
        url('SFProText-Regular.svg#SFProText-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('SFProText-RegularItalic.eot');
    src: url('SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('SFProText-RegularItalic.woff2') format('woff2'),
        url('SFProText-RegularItalic.woff') format('woff'),
        url('SFProText-RegularItalic.ttf') format('truetype'),
        url('SFProText-RegularItalic.svg#SFProText-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('SFProText-Bold.eot');
    src: url('SFProText-Bold.eot?#iefix') format('embedded-opentype'),
        url('SFProText-Bold.woff2') format('woff2'),
        url('SFProText-Bold.woff') format('woff'),
        url('SFProText-Bold.ttf') format('truetype'),
        url('SFProText-Bold.svg#SFProText-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('SFProText-BoldItalic.eot');
    src: url('SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('SFProText-BoldItalic.woff2') format('woff2'),
        url('SFProText-BoldItalic.woff') format('woff'),
        url('SFProText-BoldItalic.ttf') format('truetype'),
        url('SFProText-BoldItalic.svg#SFProText-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

