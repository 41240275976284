// html.legacyClass{
  .scroll-support {
    background-color: #f4f4f4;
  }

  .upload-template {
    display: flex;

    width: 100%;
    min-height: calc(100vh - 54px);
    
    @media screen and (max-width: 768px) {
      padding: 1rem;
    }

    .upload-template__form {
      margin: auto;
      width: 60%;
      padding: 3rem;
      border-radius: 5px;
      background-color: #fff;
      height: max-content;
    
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: 1500px) {
        width: 70%;
      }

      @media screen and (max-width: 1200px) {
        width: 80%;
      }

      @media screen and (max-width: 900px) {
        width: 90%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 1.5rem;
        flex-direction: column;
        margin: 0 auto;
      }

      .upload-template__wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        
        &:nth-child(1) {
          width: 65%;
          margin-right: 50px;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        
        &:nth-child(2) {
          width: 35%;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
          }
        }

        label {
          margin-top: 70px;
          font-weight: 600;
          
          @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
          }

          &:nth-child(1) {
            margin-top: 0;
          }
        }

        input,
        textarea {
          width: 100%;
          outline: none;
          height: 56px;
          padding: 10px 15px;
          border-radius: 5px;
          border: 1px solid #ccc;
          letter-spacing: 1px;
          font-size: 14px;
          color: #333;

          @media screen and (max-width: 768px) {
            height: 44px;
          }
        }

        textarea {
          outline: none;
          min-height: 150px;
          resize: none;
          
          @media screen and (max-width: 768px) {
            min-height: 140px;
          }
        }

        .file__wrapper {
          position: relative;
          height: 170px;
          border: 2px dashed #ccc;
          border-radius: 10px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;

          @media screen and (max-width: 768px) {
            height: 140px;
          }

          .file__infos {
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translateY(150%);
            width: 100%;
            color: #333;
            font-size: 14px;
            
            display: flex;
            align-items: center;
            
            &.empty {
              transform: translateY(120%);
              font-size: 13px;
              color: #777;
            }

            span {
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            i {
              width: 30px;
              min-width: 30px;
              height: 30px;
              display: block;
              background-repeat: no-repeat;
              background-size: contain;
              margin-right: 10px;

              &.invalid-type {
                background-image: url(../images/icon-invalid-type.png);
              }
              &.png {
                background-image: url(../images/icon-png.png);
              }
              &.pdf {
                background-image: url(../images/icon-pdf.png);
              }
              &.jpg {
                background-image: url(../images/icon-jpg.png);
              }
              &.jpeg {
                background-image: url(../images/icon-jpeg.png);
              }
              &.doc, &.docx {
                background-image: url(../images/icon-doc.png);
              }
              &.xls, &.xlsx {
                background-image: url(../images/icon-xls.png);
              }
              &.ppt, &.pptx {
                background-image: url(../images/icon-ppt.png);
              }
              &.mp4 {
                background-image: url(../images/icon-mp4.png);
              }
              &.mp3 {
                background-image: url(../images/icon-mp3.png);
              }
            }
          }

          img {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            position: absolute;
          }

          .file__input {
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        .upload-template__submit {
          align-self: flex-end;
          width: 100%;
          margin-top: 80px;
          color: #fff;
          @media(min-width: 768px) {
            transition: background 0.3s ease-in-out;
          }
        }
      }
    }
  }
// }
